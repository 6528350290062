<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Vamos começar preenchendo as informações básicas
    </div>
    <v-row id="importacao-titulo" class="mx-auto">
      <v-col cols="12">
        <validation-provider v-slot="{ errors }" rules="required">
          <v-text-field
            id="fs-titulo-importacao-dados"
            label="Título da importação"
            outlined
            :error-messages="errors"
            v-model="titulo"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row id="importacao-descricao" class="mx-auto">
      <v-col cols="12">
        <v-text-field
          id="fs-descricao-importacao-dados"
          label="Descrição (opcional)"
          outlined
          v-model="descricao"
        />
      </v-col>
    </v-row>
    <!-- <v-row id="importacao-empresa" class="mx-auto">
      <v-col cols="12">
        <select-company
          id="fs-empresa-importacao-dados"
          :type="'outlined'"
          @companySelected="companySelected"
        />
      </v-col>
    </v-row> -->
    <v-row id="importacao-tipo" class="mx-auto">
      <v-col cols="12">
        <v-select
          id="fs-tipo-importacao-dados"
          v-model="tipoImportacao"
          name="tipoImportacao"
          label="Tipo de Importação"
          :items="tiposImportacoes"
          outlined
          class="mb-0 pb-0"
          no-data-text="Nenhum tipo de importação disponível"
        />
      </v-col>
    </v-row>
    <v-row id="importacao-mes" class="mx-auto">
      <v-col cols="12" id="fs-mes-dados-importacao-dados">
        <input-month label="Mês dos dados" @monthSelected="monthSelected" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: "FirstTabContent",
    components: {
      SelectCompany: () => import("@/components/general/SelectCompany.vue"),
      InputMonth: () => import("@/components/general/InputMonth.vue"),
    },
    data() {
      return {
        titulo: null,
        descricao: null,
        companyId: null,
        tipoImportacao: null,
        tiposImportacoes: [
          {
            value: "TEC_AL",
            text: "TEC_AL (Alimentadores)",
          },
          {
            value: "TEC_SUB",
            text: "TEC_SUB (Subestações)",
          },
          {
            value: "TEC_LD",
            text: "TEC_LD (Linhas de Distribuição)",
          },
        ],
        mes: null,
      };
    },
    computed: {
      data() {
        return this.mes ? `${this.mes}-01` : null;
      },
      dataRegistroObrigatorio() {
        return (
          this.tipoImportacao && this.tipoImportacao.data_registro_obrigatorio
        );
      },
      arquivoModeloImportacao() {
        return this.tipoImportacao?.arquivo_modelo_importacao;
      },
    },
    watch: {
      titulo() {
        this.emitEventImportUpdated();
      },
      descricao() {
        this.emitEventImportUpdated();
      },
      companyId() {
        this.emitEventImportUpdated();
      },
      indgerVersion() {
        this.emitEventImportUpdated();
      },
      tipoImportacao() {
        this.emitEventImportUpdated();
      },
      mes() {
        this.emitEventImportUpdated();
      },
    },
    methods: {
      tipoSelected(tipoImportacao) {
        this.tipoImportacao = tipoImportacao;
      },
      companySelected(company) {
        this.companyId = company.id;
      },
      monthSelected(month) {
        this.mes = month;
      },
      emitEventImportUpdated() {
        this.$emit("import:updated", {
          titulo: this.titulo,
          descricao: this.descricao,
          companyId: this.companyId,
          indgerVersion: this.indgerVersion,
          tipoImportacao: this.tipoImportacao,
          mes: this.mes,
          data: this.data,
        });
      },
    },
  };
</script>
